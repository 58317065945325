import {
  Card,
  Dropdown,
  Grid,
  Spacing,
  Wrap,
  Icon,
  Label,
  Lead,
} from '@etchteam/mobius';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Gravatar from 'react-gravatar';
import { useTranslation } from 'react-i18next';

import styles from './Nav.module.scss';
import tools from './tools';

import TitleBar from 'components/canvas/TitleBar/TitleBar';
import Logomark from 'components/content/Logomark/Logomark';
import { useUser } from 'lib/hooks';

const appTitles = {
  benchmark: 'Benchmarking',
  scheme: 'Scheme search',
  'costing-tool': 'Costing tool',
  'information-about-data': 'Information about data',
  account: 'Account',
  contact: 'Contact',
  admin: 'Admin',
  'manage-data': 'Manage data',
};

const baseUrl = (url) => {
  const parts = url.split('/');
  const first = parts[1];

  return first || '';
};

const appTitle = (url) => appTitles[baseUrl(url)] || null;

const stringToHslColor = (str, s, l) => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

const Nav = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const router = useRouter();
  const url = router.pathname;
  const [user] = useUser();

  const toggle = (e?: any) => {
    e.preventDefault();
    setOpen(!open);
  };

  const initials = user?.firstName
    ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`
    : '';
  const adminUrl =
    process.env.NEXT_PUBLIC_ADMIN_URL || 'https://admin.wrap.ngo';

  return (
    <nav
      className={classNames(styles.nav, {
        [`${styles['nav--open']}`]: open,
      })}
    >
      <Grid justify="space-between" spacing="none">
        <Grid.Item>
          <a
            href="#nav-open"
            className={classNames(
              styles.nav__item,
              styles['nav__item--toggle'],
            )}
            onClick={toggle}
          >
            <Icon icon="menu" title="Menu" />
          </a>
          <div
            className={classNames(styles.nav__item, styles['nav__item--logo'])}
          >
            <Logomark type="white" url="/dashboard" />
          </div>
          {!!appTitle(url) && (
            <Link href={`/${baseUrl(url)}`} className={styles.nav__item}>
              <strong>{appTitle(url)}</strong>
            </Link>
          )}
        </Grid.Item>
        <Grid.Item>
          <Dropdown
            open={userMenuOpen}
            onClickAway={() => setUserMenuOpen(false)}
          >
            <div className={styles.nav__item}>
              <a
                href="#user-menu"
                className={styles.nav__user}
                style={{
                  backgroundColor: stringToHslColor(
                    `${user.firstName} ${user.lastName}`,
                    30,
                    80,
                  ),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setUserMenuOpen(!userMenuOpen);
                }}
              >
                <span className={styles['nav__user-text']}>{initials}</span>
                <span className={styles['nav__user-gravatar']}>
                  <Gravatar email={user.email} size={44} default="blank" />
                </span>
              </a>
            </div>
            <Dropdown.Content>
              <>
                {/* eslint-disable jsx-a11y/click-events-have-key-events */}
                {/* eslint-disable jsx-a11y/no-static-element-interactions */}
                {/* Just catching the click event on the links inside, stop bullying me */}
                <div
                  id="user-menu"
                  className={styles.nav__dropdown}
                  onClick={() => {
                    setUserMenuOpen(!userMenuOpen);
                  }}
                >
                  {/* eslint-enable jsx-a11y/click-events-have-key-events */}
                  {/* eslint-enable jsx-a11y/no-static-element-interactions */}
                  <Link
                    href="/account"
                    className={styles['nav__dropdown-link']}
                  >
                    <Icon icon="user" />
                    {' Your '}
                    {t('account.account')}
                  </Link>
                  <Link
                    href="/manage-data"
                    className={styles['nav__dropdown-link']}
                  >
                    <Icon icon="edit" /> {t('manageData.manageData')}
                  </Link>
                  {!!user.isAdmin && (
                    <a
                      href={adminUrl}
                      className={styles['nav__dropdown-link']}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon="external-link" /> {t('admin.adminArea')}
                    </a>
                  )}
                </div>
              </>
            </Dropdown.Content>
          </Dropdown>
        </Grid.Item>
      </Grid>
      <div className={styles.nav__open} id="nav-open">
        <div className={styles.nav__navbar}>
          <TitleBar skin="muted">
            <Grid align="center" spacing="none">
              <Grid.Item>
                <a
                  href="#nav-open"
                  className={classNames(
                    styles.nav__item,
                    styles['nav__item--toggle'],
                  )}
                  onClick={toggle}
                >
                  <Icon icon="close" title="Close" />
                </a>
              </Grid.Item>
              <Grid.Item>
                <Spacing left="xs">Close</Spacing>
              </Grid.Item>
            </Grid>
          </TitleBar>
        </div>
        <div
          className={styles.nav__content}
          onClick={() => setOpen(false)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e && e.code === 'Enter') {
              setOpen(false);
            }
          }}
        >
          <div className={styles.nav__inner}>
            <Wrap>
              <Lead>
                <Spacing bottom="md">
                  <p>{t('dashboard.tools.general.user.tools')}</p>
                </Spacing>
              </Lead>
              <Grid wrap>
                {tools.map((tool) => (
                  <Grid.Item xs={12} lg={6} xl={4} flex key={tool.url}>
                    <Card
                      as={Link}
                      href={tool.url}
                      border={false}
                      disabled={tool.disabled}
                    >
                      <Card.Header>
                        <Grid spacing="sm" align="center">
                          <Grid.Item>
                            <h3>
                              <strong>{tool.title}</strong>
                            </h3>
                          </Grid.Item>
                          {!!tool.disabled && (
                            <Grid.Item>
                              <Label>Coming soon</Label>
                            </Grid.Item>
                          )}
                        </Grid>
                      </Card.Header>
                      <Card.Body>
                        <Image
                          className={styles.nav__image}
                          src={tool.img}
                          role="presentation"
                          alt={tool.title}
                          width={424}
                          height={212}
                          sizes="100vw"
                        />
                      </Card.Body>
                    </Card>
                  </Grid.Item>
                ))}
              </Grid>
            </Wrap>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
